
import { ref, defineComponent, onMounted, computed } from "vue";
import { Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Skeletons from "./components/Skeletons.vue";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ProgressBar from 'primevue/progressbar';

import moment from "moment";
interface ReservationDetails {
  reservaId: string;
  segmento:string;
  marca:string;
  modelo:string;
}

interface Segmento {
   segmento:string;
}
interface Marca {
   marca:string;
}
interface Modelo {
   modelo:string;
}

interface FiltroDisponibilidad {
   reservaId: string;
   segmento:string|undefined|null;
   marca:string|undefined|null;
   modelo:string|undefined|null;
}

export default defineComponent({
  name: "dashboard",
  components: {
   /*ErrorMessage,*/
   ProgressBar,
   Field,
   Form,
   Skeletons

  },
  setup() {
    const router = useRouter();
    const store = useStore();
    
    const segmentos = ref<Array<Segmento>>([]);
    const marcas = ref<Array<Marca>>([]);
    const modelos = ref<Array<Modelo>>([]);
    const flotaActual = ref<ReservationDetails>();
    const visibleBottom  = ref(false);
    const reservationValidator = Yup.object().shape({
      //estacionRetiro: Yup.string().required("Es obligatorio").label("Lugar de retiro"),
      //estacionDevolucion: Yup.string().required("Es obligatorio").label("Lugar de devolución"),
      //fechaRetiro: Yup.date().required("Es obligatorio").label("Fecha de retiro"),
      //fechaDevolucion: Yup.date().required("Es obligatorio").label("Fecha de devolucion"),
      //promocion: Yup.string().required("Es obligatorio").label("Promocion"),
      //puntos: Yup.number().required("Es obligatorio").label("Puntos"),
    });
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      obtenerDisponibilidad(0);
      obtenerReserva();
    });
    const filtroDisponibilidad = ref<FiltroDisponibilidad>({segmento:null, marca:null, modelo:null, reservaId: store.getters.reservaId});

    const obtenerReserva = () => {
      store
        .dispatch(Actions.GET_RESERVA, store.getters.reservaId)
        .then(() => {
          loading.value = false;
         })
        .catch(() => {
          const [error] = Object.keys(store.getters.getReservaErrors);
        });
    };
    const currentReserva = computed(() => {
      return store.getters.currentReserva;
    });
    const saveChanges = (flota) => {
      store
          .dispatch(Actions.UPD_RESERVA_VEHICULO, {
                                             reservaId: store.getters.reservaId,
                                             codigoSegmentoVehiculo:flota.codigoSegmento,
                                             segmentoVehiculo:flota.segmento,
                                             marcaVehiculo:flota.marca,
                                             modeloVehiculo:flota.modelo,
                                             esReemplazo:flota.esReemplazo, esRack:flota.esRack,
                                             codigoEstacionRetiro : flota.codigoEstacionRetiro,
                                             codigoEstacionDevolucion : flota.codigoEstacionDevolucion,
                                             estacionRetiroRentway : flota.estacionRetiroRentway,
                                             estacionDevolucionRentway : flota.estacionDevolucionRentway,
                                             patenteOferta : flota.cantidad==1?flota.patente:'',
                                             urlImagen : flota.imageUrl})
          .then(() => {
            router.push({ name: "reservation-personal" });
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getReservaErrors);
            Swal.fire({
              text: store.getters.getReservaErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intentar nuevamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    };
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const disponibilidad = computed(() => {
      return store.getters.allDisponibilidad;
    });
    const disponibilidadCercana = computed(() => {
      return store.getters.allDisponibilidadCercana;
    });

    const changeSegmento = () => {
      filtroDisponibilidad.value.marca = "";
      filtroDisponibilidad.value.modelo = "";
      obtenerDisponibilidad(1);
    }

    const changeMarca = () => {
      filtroDisponibilidad.value.modelo = "";
      obtenerDisponibilidad(2);
    }
    const changeModelo = () => {
      obtenerDisponibilidad(3);
    }
    const obtenerDisponibilidad = (filterLevel:number) => {
      store
        .dispatch(Actions.GET_DISPONIBILIDAD, filtroDisponibilidad.value)
        .then(() => {
          loading.value = false;
            if(filterLevel<=0) {
               segmentos.value = _.orderBy(_.chain(store.getters.allDisponibilidad)
               .groupBy("segmento")
               .map((value, key) => ({ segmento: key, procesos: value }))
               .value(), ["segmento"], ["asc"]);
            }
            if(filterLevel<=1) {
               marcas.value = _.orderBy(_.chain(store.getters.allDisponibilidad)
               .groupBy("marca")
               .map((value, key) => ({ marca: key, procesos: value }))
               .value(), ["marca"], ["asc"]);
            }
            if(filterLevel<=2) {
               modelos.value = _.orderBy(_.chain(store.getters.allDisponibilidad)
               .groupBy("modelo")
               .map((value, key) => ({ modelo: key, procesos: value }))
               .value(), ["modelo"], ["asc"]);
            }
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getDisponibilidadErrors);
          Swal.fire({
              text: store.getters.getDisponibilidadErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intentar nuevamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            }).then(() => {
               router.push({ name: "reservation-overview", params:{id:filtroDisponibilidad.value.reservaId} });
            })
        });
    };

    const obtenerDisponibilidadCercana = (patente:string) => {
      loading.value = true;
      store
        .dispatch(Actions.GET_DISPONIBILIDAD_CERCANA, {patente, reservaId:filtroDisponibilidad.value.reservaId})
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getDisponibilidadErrors);
          Swal.fire({
              text: store.getters.getDisponibilidadErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intentar nuevamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            }).then(() => {
               router.push({ name: "reservation-overview", params:{id:filtroDisponibilidad.value.reservaId} });
            })
        });
    };
    

    const ajustarYContinuar = (fecha: Date) => {
      loading.value = true;
         store
            .dispatch(Actions.UPD_RESERVA, 
            {
               estacionRetiro: "",
               estacionDevolucion: "",
               direccionRetiro: "",
               direccionDevolucion: "",
               comunaRetiro: "",
               comunaDevolucion: "",
               esRetiroPersonalizado:false,
               esDevolucionPersonalizada:false,
               fechaRetiro: fecha,
               fechaDevolucion: moment().add(2, 'days').toDate(),
               horaRetiro: moment("20111031 12:00:00", "YYYYMMDD hh:mm:ss").toDate(),
               horaDevolucion: moment("20111031 12:00:00", "YYYYMMDD hh:mm:ss").toDate(),
               nombre: `${currentUser.value.given_name} ${currentUser.value.family_name}`,
               email: currentUser.value.email,
               telefono: currentUser.value.phone_number,
               rut: currentUser.value?.driverId?.toString(),
               clientId: currentUser.value?.clientId,
               driverId: currentUser.value?.driverId,
               reservaId:store.getters.reservaId
            })
            .then(() => {
               visibleBottom.value=false;
               loading.value = false;
               obtenerReserva();
               obtenerDisponibilidad(0);
            })
            .catch(() => {
               const [error] = Object.keys(store.getters.getReservaErrors);
               Swal.fire({
               text: store.getters.getReservaErrors[error],
               icon: "error",
               buttonsStyling: false,
               confirmButtonText: "Intentar nuevamente!",
               customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
               },
               });
            });
    };

    const verFlota = (flota) => {
      visibleBottom.value=true;
      flotaActual.value = flota;
    };

    const imagen = (flota)=>{
      if(flota.imageUrl && flota.imageUrl!='')
         return flota.imageUrl;
      return `/media/flota/${flota.segmento}/${flota.marca}/${flota.modelo.replace('/','-')}.png`;
    };

    const imageUrlAlt = (event) => {
      event.target.src = "/media/mock/auto3.png"
    }
    const loading = ref(true);
    return {
      loading,
      currentUser,
      disponibilidad,
      marcas,
      modelos,
      obtenerDisponibilidad,
      saveChanges,
      filtroDisponibilidad,
      reservationValidator,
      segmentos,
      changeSegmento,
      changeMarca,
      changeModelo,
      imagen,
      imageUrlAlt,
      visibleBottom,
      flotaActual,
      verFlota,
      disponibilidadCercana,
      obtenerDisponibilidadCercana,
      moment,
      ajustarYContinuar,
      currentReserva
    };
  },
});
